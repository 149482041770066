<template>
  <div>
    <Header :navbg='true'></Header>
    <div class="content">
      <div class="banner">
        <img src="../../assets/images/contact/lxwm.jpg" alt="">
      </div>

      <div class="lxwm">
        <div class="title">联系我们</div>
        <div class="tool-warp">

          <div class="tool" v-for="(tool,index) in toolList" :key="index">
            <img :src="tool.img" alt="">
            <div class="right">
              <div class="title">{{tool.title}}</div>
              <div class="value">{{tool.value}}</div>
            </div>
          </div>

        </div>

        <div class="qrcode-warp">
          <div class="warp">
            <img src="../../assets/images/official-qrcode.png" alt="小院生活公众号">
            <div class="title">小院生活公众号</div>
            <div class="desc">扫码关注了解更多小院动态</div>
          </div>
          <div class="warp">
            <img src="../../assets/images/program-qrcode.png" alt="小院生活小程序">
            <div class="title">小院生活小程序</div>
            <div class="desc">预订小院产品、门店消费、图书借阅</div>
          </div>
        </div>
      </div>

    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/head2022.vue'
import Footer from '@/components/foot2022.vue'

export default {
  name:"lifeContact",
  data () {
    return {
      toolList: [
        {
          img: require("../../assets/images/contact/lxwm1.png"),
          title: "电话",
          value: "400-900-5258"
        },
        {
          img: require("../../assets/images/contact/lxwm2.png"),
          title: "邮箱",
          value: "im@xiaoyuan.com.cn"
        },
        {
          img: require("../../assets/images/contact/lxwm3.png"),
          title: "邮编",
          value: "710065"
        },
        {
          img: require("../../assets/images/contact/lxwm4.png"),
          title: "地址",
          value: "陕西省西安市雁塔区朱雀大街3号8幢"
        },
        {
          img: require("../../assets/images/contact/lxwm5.png"),
          title: "公司主页",
          value: "http://xiaoyuan.com.cn"
        }
      ]
    }
  },
  components: {
    Header,
    Footer,
  }
}
</script>

<style lang="scss" scoped>
.content .banner > img {
  width: 100%;
}
.lxwm {
  padding: 26px 16px 62px;
  color: #303233;
  font-size: 14px;
}
.lxwm > .title {
  font-size: 18px;
  line-height: 18px;
}
.lxwm .tool-warp {
  margin-top: 26px;
  border-bottom: 1px solid #f4f4f4;
}
.lxwm .tool-warp .tool {
  display: flex;
  align-items: center;
  margin: 20px 0;
}
.lxwm .tool-warp .tool img {
  width: 35px;
  height: 35px;
}
.lxwm .tool-warp .tool .right {
  margin-left: 16px;
  flex: 1;
}
.lxwm .tool-warp .tool .right .value {
  color: #999999;
}

.lxwm .qrcode-warp {
  text-align: center;
  font-size: 14px;
}
.lxwm .qrcode-warp .warp {
  margin-top: 30px;
}
.lxwm .qrcode-warp .warp img {
  width: 130px;
  height: 130px;
  margin: auto;
  margin-bottom: 20px;
}
.lxwm .qrcode-warp .warp .desc {
  color: #999999;
}
</style>